import React from 'react'
import BlockContent from '../block-content'

const ProjectInfo = ({ year, location, surface, address, concept, available, pieces }) => (
  <div className='info-container grid-4'>
    {!!year &&
    <div className='year' data-sal='slide-down' data-sal-duration='400' data-sal-easing='ease-out'>
      <p>Year</p>
      <h4>{year}</h4>
    </div>
    }
    {!!location &&
      <div className='location' data-sal='slide-down' data-sal-duration='400' data-sal-easing='ease-out'>
        <p>Location</p>
        <h4>{location}</h4>
      </div>
    }
    {!!surface &&
      <div className='surface' data-sal='slide-down' data-sal-delay='100' data-sal-duration='400' data-sal-easing='ease-out'>
        <p>Surface</p>
        <h4>{surface} m<sup>2</sup></h4>
      </div>
    }
    {!!address &&
      <div className='address' data-sal='slide-down' data-sal-delay='200' data-sal-duration='500' data-sal-easing='ease-out'>
        <p>Address</p>
        <h4>{address}</h4>
      </div>
    }
    {!!concept &&
      <div className='concept' data-sal='slide-down' data-sal-delay='200' data-sal-duration='600' data-sal-easing='ease-out'>
        <p>Concept</p>
        <div className='block-container'>
          <BlockContent blocks={concept || []}/>
        </div>
      </div>
    }
    {!!pieces && pieces.length > 0 &&
      <div className='pieces' data-sal='slide-down' data-sal-delay='400' data-sal-duration='650' data-sal-easing='ease-out'>
        <p>Pieces</p>
        <ul>
          {pieces.map((piece, i) => {
            return(
              <li key={i*500}>{piece}</li>
            )
          })}
        </ul>
      </div>
    }
    {!!available &&
      <p className='available' data-sal='slide-down' data-sal-delay='850' data-sal-duration='500' data-sal-easing='ease-out'>Available <a href={available} target="__blank">here</a></p>
    }
  </div>
)

export default ProjectInfo
