import React from 'react'

const ProjectTitle = ({ title }) => (
  <div className='title-container grid' data-sal='slide-down' data-sal-delay='300' data-sal-duration='1000' data-sal-easing='ease-out'>
    <p>Project</p>
    <h1>{title}</h1>
  </div>
)

export default ProjectTitle
