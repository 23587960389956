import React, { useState } from 'react'
import { Link } from 'gatsby'
import { window } from 'browser-monads'
import smoothscroll from 'smoothscroll-polyfill'
import classNames from 'classnames'
import Arrow from '../../assets/svg/arquetipo-arrow.svg'
import ArrowTop from '../../assets/svg/arquetipo-arrow-top.svg'
import Image from '../image'

const Controls = ({ previous, next }) => {
  const [visible, setVisible] = useState(null)

  let prevClasses = classNames({
    'visible': visible === 'prev'
  })
  let nextClasses = classNames({
    'visible': visible === 'next'
  })

  const scrollTop = () => {
    window.__forceSmoothScrollPolyfill__ = true;
    smoothscroll.polyfill()
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return(
    <div className='controls grid' data-sal='fade' data-sal-delay='300' data-sal-duration='1200' data-sal-easing='ease-out'>
      <Link className='cta' to='/projects'>Back to projects <Arrow/></Link>
      <button type='button' onClick={scrollTop}><ArrowTop/> Back to top</button>
      <div className='project-control grid-10'>
        <div className='images'>
          <Image className={prevClasses} fluid={previous.outsideCover.asset.fluid} alt={`${previous.title} - Arquetipo Estudio`}/>
          <Image className={nextClasses} fluid={next.outsideCover.asset.fluid} alt={`${next.title} - Arquetipo Estudio`}/>
        </div>
        <div className="titles grid-10">
          <div className='prev'>
            <Link onMouseEnter={() => setVisible('prev')} onMouseLeave={() => setVisible('')} to={`/projects/${previous.slug.current}`}><h1>{previous.title}</h1></Link>
            <Link onMouseEnter={() => setVisible('prev')} onMouseLeave={() => setVisible('')} to={`/projects/${previous.slug.current}`}><p>Previous</p></Link>
          </div>
          <div className='cta-home'>
            <Link to='/'><h1>Home</h1></Link>
          </div>
          <div className='next'>
            <Link onMouseEnter={() => setVisible('next')} onMouseLeave={() => setVisible('')} to={`/projects/${next.slug.current}`}><h1>{next.title}</h1></Link>
            <Link onMouseEnter={() => setVisible('next')} onMouseLeave={() => setVisible('')} to={`/projects/${next.slug.current}`}><p>Next</p></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Controls
