import React, { useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from '../components/image'
import ProjectTitle from '../components/project/title'
import ProjectInfo from '../components/project/info'
import Controls from '../components/project/controls'

function Project (props) {
  const {
    project, previous, next } = props.pageContext,
    { insideCover, title, firstRow, secondRow, thirdRow, fourthRow } = project,
    { year, location, surface, address, _rawConcept, pieces, available } = firstRow.info;

    useEffect(() => {
      const footer = document.querySelector('footer')

      footer.style.marginTop = `60px`
    })

  return(
    <Layout>
      <SEO title={title}/>
      <div className="project-inside grid">
        <Image className='cover' fluid={insideCover.asset.fluid} alt={`${title} - Arquetipo Estudio`}/>
        <ProjectTitle title={title}/>
        <div className='first-image' data-sal='fade' data-sal-duration='400' data-sal-easing='ease-out'>
          <Image className='first-image' fluid={firstRow.image.asset.fluid} alt={`${title} - Arquetipo Estudio`}/>
        </div>
        <ProjectInfo
          year={year}
          location={location}
          surface={surface}
          address={address}
          concept={_rawConcept}
          pieces={pieces}
          available={available}
        />
        <div className='second-image' data-sal='fade' data-sal-duration='400' data-sal-easing='ease-out'>
          <Image fluid={secondRow.image.asset.fluid} alt={`${title} - Arquetipo Estudio`}/>
        </div>

        <div className='third-image' data-sal='fade' data-sal-duration='400' data-sal-easing='ease-out'>
          <Image fluid={thirdRow.image.asset.fluid} alt={`${title} - Arquetipo Estudio`}/>
        </div>
        <div className='fourth-image' data-sal='fade' data-sal-duration='400' data-sal-easing='ease-out'>
          <Image fluid={thirdRow.smallImage.asset.fluid} alt={`${title} - Arquetipo Estudio`}/>
        </div>

        <div className='fifth-image' data-sal='fade' data-sal-duration='400' data-sal-easing='ease-out'>
          <Image fluid={fourthRow.smallImage.asset.fluid} alt={`${title} - Arquetipo Estudio`}/>
        </div>
        <div className='sixth-image' data-sal='fade' data-sal-duration='400' data-sal-easing='ease-out'>
          <Image fluid={fourthRow.image.asset.fluid} alt={`${title} - Arquetipo Estudio`}/>
        </div>

        <Controls previous={previous} next={next}/>
      </div>
    </Layout>
  )
}

export default Project
